import router from './routers.js'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getInfo } from '@/api/login'
import { filterAsyncRouter } from '@/store/modules/permission'
// import { menus_buildNew } from '@/api/menu.js'
import Layout from '@/components/Layout/index.vue'

const whiteList = ['/login', '/openHome','/openFiles','/openTools','/openDataBank','/toolsText','/toolsTextRes','/toolsQuestion','/toolsBug','/toolsData', '/openMoreFile','/openMoreFile2','/openMoreFile3','/openMoreFile4','/openDetils','/openDetilsFiles','/openDetilsTool']// no redirect whitelist
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title 
  }
  if (getToken()) {
    if (to.path === '/login') {
        next({ path: '/' })
    } else {
      if(store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then((res) => {
          loadMenus(next, to)
        }).catch((err) => {
          store.dispatch('LogOut').then(() => {
            location.reload()
          })
        })
      }else if(store.getters.loadMenus){
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus')
        loadMenus(next, to)
      }else{
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) { //在免登录白名单，直接进入
      next()
    } else {
      next(`/openHome`) // 否则全部重定向到登录页
    }
  }
})

export const loadMenus = (next, to) => {
  getInfo().then((r)=>{
    const sdata = JSON.parse(JSON.stringify(r.data.menuVos))
    const rdata = JSON.parse(JSON.stringify(r.data.menuVos))
    const cdata = JSON.parse(JSON.stringify(r.data.menuVos))
    const sidebarRoutes = filterAsyncRouter(sdata)
    const rewriteRoutes = filterAsyncRouter(rdata, false, true)
    
    store.dispatch('GenerateRoutes', rewriteRoutes).then(() => { // 存储路由
      operMenu(cdata,'root')
      next({ ...to, replace: true })
    })
    store.dispatch('SetSidebarRouters', sidebarRoutes)     
  })
    
}


function operMenu(arrs, parentName){
  arrs.forEach(item => {
      router.addRoute(parentName,{
        path:item.path,
        name:item.name,
        meta:item.meta,
        component: parentName=='root'?Layout:() => import(`@/views/${item.component}`),
      })
      if(item.children&&item.children.length>0){
        operMenu(item.children, item.name)
      }
  })
}
