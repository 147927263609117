<template>
     <el-container>
        <el-header class="header">
            <div class="headerLeft">
                <el-menu 
                text-color="#fff"
                active-text-color="#fff"
                :default-active="activeMenu"
                mode="horizontal" 
                :ellipsis="false"
                router>
                <div class="nameLogo">
                    <img src="../../assets/siteImg/logo.png"/>
                    馆藏文物预防性保护风险防控管理服务平台
                </div>
                <div class="flex-grow" /> 
                <el-menu-item index="/openHome">首页</el-menu-item>
                <el-menu-item index="/openFiles">案例库</el-menu-item> 
                <el-menu-item index="/openDataBank">资料库</el-menu-item>
                <el-menu-item index="/openTools">应用指南</el-menu-item>
                <el-menu-item index="/openEnvironmentRisk" v-if="user.token">风险评估</el-menu-item>
                </el-menu>
            </div>
            <div class="headerRight">
                <div v-if="!user.token">
                    <el-button @click="goLogin" link>
                        <span style="color: white;">登录</span>
                    </el-button>
                    <span>|</span>
                    <el-button @click="goRegister" link>
                        <span style="color: white;">注册</span>
                    </el-button>
                </div>
                <el-dropdown @command="handleCommand" v-else>
                    <div class="nameCss">
                        <div class="imgUser">
                            <svg-icon icon-class="user"/>
                        </div>
                        <div style="color: white;">
                            {{user.user.username}}
                            <svg-icon icon-class="arrowDown"/>
                        </div>
                    </div>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="a" v-if="user.user.type!=0">数据中心</el-dropdown-item>
                        <el-dropdown-item command="b" v-if="user.user.type!=0">管理中心</el-dropdown-item>
                        <el-dropdown-item command="c">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </el-header>
        <el-main>
            <div class="mainContain">
                <router-view/>
            </div>
        </el-main>
        <el-footer>
            <div class="bottomBox">
                <div class="BOttomItem" style="border-left: 0px solid white;">
                    <div class="Boname">上海区域中心</div>
                    <div>
                        <el-link href="https://www.shanghaimuseum.net/mu/frontend/pg/index" :underline="false" target="_blank">上海博物馆</el-link><br/>
                        <el-link href="http://www.shh-shrhmuseum.org.cn/historymuseum/historymuseum/index.html" :underline="false" target="_blank">上海市历史博物馆</el-link><br/>
                        上海市文物局集中库房<br/>
                    </div>
                </div>
                <div class="BOttomItem">
                    <div class="Boname">重庆区域中心</div>
                    <div>
                        <el-link href="http://www.3gmuseum.cn/" :underline="false" target="_blank">重庆中国三峡博物馆</el-link><br/>
                        <el-link href="http://www.cqyybwg.com/" :underline="false" target="_blank">云阳博物馆</el-link><br/>
                        巫山博物馆<br/>
                    </div>
                </div>
                <div class="BOttomItem">
                    <div class="Boname">陕西区域中心</div>
                    <div class="Bomain">  
                        <div>
                            <el-link href="http://www.sxwby.com/" :underline="false" target="_blank">陕西省文物保护研究院</el-link><br/>
                            <el-link href="https://www.bmy.com.cn/index.html" :underline="false" target="_blank">秦始皇帝陵博物院</el-link><br/>
                            <el-link href="http://www.famensi.com/" :underline="false" target="_blank">法门寺博物馆</el-link><br/>
                            <el-link href="https://www.sxhm.com/" :underline="false" target="_blank">陕西历史博物馆</el-link>
                        </div>
                        <div>
                            &nbsp;&nbsp;<el-link href="http://www.bjqtm.com/" :underline="false" target="_blank">宝鸡青铜器博物院</el-link><br/>
                            &nbsp;&nbsp;<el-link href="https://www.beilin-museum.com/" :underline="false" target="_blank">西安碑林博物馆</el-link>
                        </div>
                    </div>
                </div>

                <div class="BOttomItem">
                    <div class="Boname">建设单位</div>
                    <div class="Bomain">  
                        <div>
                            <el-link href="https://www.shanghaimuseum.net/mu/frontend/pg/index" :underline="false" target="_blank">上海博物馆</el-link><br/>
                            <el-link href="https://zsb.ecust.edu.cn/" :underline="false" target="_blank">华东理工大学</el-link><br/>
                            <el-link href="https://www.bupt.edu.cn/" :underline="false" target="_blank">北京邮电大学</el-link><br/>
                            <el-link href="https://www.itei.cn/" :underline="false" target="_blank">机械工业仪器仪表综合技术经济研究所</el-link>
                        </div>
                        <div>
                            &nbsp;&nbsp;<el-link href="http://www.sxwby.com/" :underline="false" target="_blank">陕西省文物保护研究院</el-link><br/>
                            &nbsp;&nbsp;<el-link href="http://www.3gmuseum.cn/" :underline="false" target="_blank">重庆中国三峡博物馆</el-link><br/>
                            &nbsp;&nbsp;<el-link href="http://cetccq.cetc.com.cn/" :underline="false" target="_blank">重庆声光电智联电子有限公司</el-link><br/>
                            &nbsp;&nbsp;<el-link href="http://www.microwise-system.com/" :underline="false" target="_blank">西安元智系统技术有限责任公司</el-link>
                        </div>
                        <div>
                            &nbsp;&nbsp;<el-link href="http://www.ptologie.com/" :underline="false" target="_blank">北京鹏通高科科技有限公司</el-link><br/>
                            &nbsp;&nbsp;上海腾悦信息科技有限公司
                        </div>
                    </div>
                </div>
                <div class="BOttomItem">
                    <div class="Boname">相关链接</div>
                    <div>
                        <el-link href="https://www.acca21.org.cn/" :underline="false" target="_blank">中国21世纪议程管理中心</el-link><br/>
                        <el-link href="https://www.most.gov.cn/index.html" :underline="false" target="_blank"> 科学技术部</el-link><br/>
                        <el-link href="http://www.ncha.gov.cn/index.html" :underline="false" target="_blank">国家文物局</el-link>
                    </div>
                </div>
            </div>
        </el-footer>
        <registerForm ref="register" />
    </el-container>
</template>
<script>
import registerForm from './modules/register.vue'
import { mapGetters } from 'vuex'
export default {
    components:{
        registerForm 
    },
    data(){
        return{ 
        }
    },
    computed:{
    
        ...mapGetters([
            'user',
        ]),
    
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
    },
    mounted(){
    },
    methods:{
        goRegister(){
            this.$refs.register.open()
        },
        goLogin(){
            this.$router.push('/login')
        },
        logOut(){
            this.$confirm(
                '是否退出登录?',
                '提示信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                this.$store.dispatch('LogOut').then(()=>{
                    location.reload()
                })
            })
            .catch(() => {
                
            })
        },
        handleCommand(type){
           if(type == 'a'){
            switch (this.user.zoneTree.type){
                case 1:
                    this.urlResolve('countryScreenNew')
                    break;
                case 2:
                    this.urlResolve('areaScreenNew')
                    break;
                default: 
                    this.urlResolve('siteHome')
            }
           }else if(type == 'b'){
                this.urlResolve('sHome')
           }else{
                this.logOut()
           }
        },
        urlResolve(name){
            let routeUrl = this.$router.resolve({
                'name':name
            })
            window.open(`${routeUrl.href}`, '_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
.mainContain{
    width: 100%;
    overflow: auto;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index:10
}
.headerLeft{
    width: calc(100% - 200px);
    height: 100%;
}
.headerRight{
    width: 150px;
    margin-left: 20px;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
}

/deep/.el-dialog__header{
    margin-right: 0px;
    background-color: rgb(48, 48, 231);
}

/deep/.el-dialog__title{
    color: white;
}
.nameCss{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgUser{
        width: 30px;
        height: 30px;
        margin-right:6px;
        display: inline-block;
        border-radius: 50%;
        background: rgb(225, 225, 225);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(0, 0, 0);
    }
}
.el-header{
  background-color:rgb(26, 118, 255);
}
.el-main{
    width: 100%;
    height: 100%;
    padding: 0px;
    margin-bottom: 20px;
    overflow: auto;
    box-sizing: border-box;
}

.flex-grow {
  flex-grow: 1;
}
.el-menu{
    background-color: rgba($color: #000000, $alpha: 0);
}
.el-menu--horizontal{
  border-bottom: solid 0px #6691f5;
}
.nameLogo{
  font-size: 28PX;
  font-weight: bold;
  height: 59PX;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  color: white;
  img{
    width: 26PX;
    height: 50PX;
    object-fit: contain;
    margin-right: 10PX;
  }
}
  .bottomBox{
    padding: 30px 0px 50px 0px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #0A3696;
    .BOttomItem{
        padding: 0px 50px;
        color: white;
        font-size: 12px;
        line-height: 26px;
        border-left: 1px solid white;
    }
    .Boname{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .Bomain{
        display: flex;
        justify-content: space-between;
    }
}
.el-footer {
    --el-footer-padding: 0px; 
    --el-footer-height: auto;
}

:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}
:deep(.el-link){
    color:white;
}

</style>