import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router/routers.js'
import './router/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import '@/assets/icons'
import SvgIcon from "@/components/SvgIcon"
import '@/assets/style/style.scss'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('svg-icon', SvgIcon)
app.use(ElementPlus,{
    locale: zhCn,
}).use(store).use(router).mount('#app')
