import { createRouter, createWebHistory } from 'vue-router'
import pageOut from '@/components/pageOut/index'
import openLayout from '@/components/openLayout/index'
import Layout from '@/components/Layout/index'
export const constantRouterMap = [
    {
      path: '/login',
      hidden:true,
      name:'Login',
      component:()=>import('@/views/Login/login.vue'),
      meta: { title: '登录', icon:'sy',noCache: true }
    },
    {
      path: '/countryScreen',
      name: 'countryScreen',
      hidden:true,
      component: () => import('@/views/countryScreen/index.vue'),
      meta: { title: '全国中心大屏', noCache: true}
    },
    {
      path: '/countryScreenNew',
      name: 'countryScreenNew',
      hidden:true,
      component: () => import('@/views/countryScreenNew/index.vue'),
      meta: { title: '全国中心大屏', noCache: true}
    },
    {
      path: '/areaScreenNew',
      name: 'areaScreenNew',
      hidden:true,
      component: () => import('@/views/areaScreenNew/index.vue'),
      meta: { title: '区域中心大屏', noCache: true}
    },
    {
      path: '/areaScreen',
      name: 'areaScreen',
      hidden:true,
      component: () => import('@/views/areaScreen/index.vue'),
      meta: { title: '区域中心大屏', noCache: true}
    },
    {
      path: '/site',
      redirect:'/site/siteHome',
      component:pageOut,
      hidden:true,
      outNav:1,
      meta: { title: '首页概览',noCache: true },
      children:[
        // {
        //   path: 'siteHomeOld',
        //   name:'siteHomeOld',
        //   component: () => import('@/views/siteHome/indexOld.vue'),
        //   meta: { title: '首页之前',noCache: true, activeMenu:'/openHome' }
        // },
        {
          path: 'siteHome',
          name:'siteHome',
          component:()=>import('@/views/siteHome/index.vue'),
          meta: { title: '首页概览',noCache: true, activeMenu:'/site' }
        },
      ]
    },
    {
      path: '/View',
      redirect:'/View/siteView',
      component:pageOut,
      hidden:true,
      outNav:1,
      meta: { title: '监测状态',noCache: true },
      children:[{
        path: 'siteView',
        name:'siteView',
        component: () => import('@/views/siteView/index.vue'),
        meta: { title: '监测状态',noCache: true, activeMenu:'/View' }
      }],
    },
    {
      path: '/warning',
      redirect:'/warning/whome',
      component:pageOut,
      hidden:true,
      outNav:1,
      meta: { title: '风险管理',noCache: true },
      children:[
        {
          path: 'whome',
          name:'whome',
          outNav:1,
          component:()=>import('@/views/warningHome/index.vue'),
          meta: { title: '环境风险评估',noCache: true }
        },
        {
          path: 'wres',
          name:'wres',
          component:()=>import('@/views/warningHome/modules/detiles.vue'),
          meta: { title: '评估结果',noCache: true, activeMenu:'/warning/whome' }
        },
        {
          path: 'whistory',
          name:'whistory',
          component:()=>import('@/views/warningHome/history.vue'),
          meta: { title: '历史评估记录',noCache: true, activeMenu:'/warning/whome' }
        },
        {
          path: 'wtable',
          name:'wtable',
          outNav:1,
          component:()=>import('@/views/warningTable/index.vue'),
          meta: { title: '预警事件处理',noCache: true }
        },
        {
          path: 'wplan',
          name:'wplan',
          outNav:1,
          component:()=>import('@/views/warningPlan/index.vue'),
          meta: { title: '决策支持预案',noCache: true }
        },
        {
          
          path: 'wsw',
          redirect:'/openEnvironmentRisk',
          name:'wsw',
          outNav:1,
          component:()=>import('@/views/warningPlan/index.vue'),
          meta: { title: '生物风险评估',noCache: true }
        },
      ]
    },
    {
      path: '/analysis',
      redirect:'/analysis/dataAnalysis',
      component:pageOut,
      hidden:true,
      outNav:1,
      meta: { title: '数据分析',noCache: true },
      children:[
        {
          outNav:1,
          path: 'dataAnalysis',
          name:'dataAnalysis',
          component:()=>import('@/views/dataAnalysis/index.vue'),
          meta: { title: '数据分析',noCache: true, activeMenu:'/analysis/dataAnalysis'  }
        },
      ]
    },
    {
      path: '/operation',
      redirect:'/operation/operationPage',
      component:pageOut,
      hidden:true,
      outNav:1,
      meta: { title: '站点运维',noCache: true },
      children:[
        {
          outNav:1,
          path: 'operationPage',
          name:'operationPage',
          component:()=>import('@/views/operationPage/index.vue'),
          meta: { title: '站点运维',noCache: true }
        },
      ]
    },
    {
      path: '/inside',
      redirect:'/inside/siteFloor',
      component:pageOut,
      hidden:true,
      children:[
        {
          path: 'siteFloor',
          name:'siteFloor',
          hidden:true,
          component:()=>import('@/views/siteFloor/index.vue'),
          meta: { title: '首页概览',noCache: true , activeMenu:'/site/siteHome'}
        },
        {
          path: 'siteRoom',
          name:'siteRoom',
          hidden:true,
          component:()=>import('@/views/siteRoom/index.vue'),
          meta: { title: '首页概览',noCache: true , activeMenu:'/site/siteHome'}
        },
      ] 
    },
    {
      path: '/',
      redirect:'/openHome',
      hidden:true,
      component:openLayout,
      meta: { title: '首页', noCache: true},
      children:[
        {
          path: 'openHome',
          name:'openHome',
          component: () => import('@/views/openHome/index.vue'),
          meta: { title: '首页',noCache: true, activeMenu:'/openHome' }
        },
        {
          path:'openFiles',
          name:'openFiles',
          component: () => import('@/views/openFiles/index.vue'),
          meta: { title: '案例',noCache: true, activeMenu:'/openFiles' }
        },
        // {
        //   path:'openFilesMore',
        //   name:'openFilesMore',
        //   component: () => import('@/views/openFiles/index.vue'),
        //   meta: { title: '案例',noCache: true, activeMenu:'/openFiles' }
        // },
        {
          path:'openDataBank',
          name:'openDataBank',
          component: () => import('@/views/openDataBank/index.vue'),
          meta: { title: '资料库',noCache: true, activeMenu:'/openDataBank' }
        },
        {
          path:'openEnvironmentRisk',
          name:'openEnvironmentRisk',
          component:()=>import('@/views/openEnvironmentRisk/index.vue'),
          meta:{title:'风险评估', noCache:true, activeMenu:'/openEnvironmentRisk'}
        },
        {
          path: 'openTools',
          name:'openTools',
          component: () => import('@/views/openTools/index.vue'),
          meta: { title: '应用指南',noCache: true, activeMenu:'/openTools' }
        },
        {
          path:'toolsText',
          name:'toolsText',
          component: () => import('@/views/toolsText/index.vue'),
          meta: { title: '基因对比',noCache: true, activeMenu:'/openEnvironmentRisk' }
        },
        {
          path:'toolsTextRes',
          name:'toolsTextRes',
          component: () => import('@/views/toolsResult/index.vue'),
          meta: { title: '基因对比结果',noCache: true, activeMenu:'/openEnvironmentRisk' }
        },
        {
          path:'toolsQuestion',
          name:'toolsQuestion',
          component: () => import('@/views/toolsQuestion/index.vue'),
          meta: { title: '霉菌识别',noCache: true, activeMenu:'/openEnvironmentRisk' }
        },
        {
          path:'toolsBug',
          name:'toolsBug',
          component: () => import('@/views/toolsBug/index.vue'),
          meta: { title: '害虫识别',noCache: true, activeMenu:'/openEnvironmentRisk' }
        },
        {
          path:'toolsData',
          name:'toolsData',
          component: () => import('@/views/toolsData/index.vue'),
          meta: { title: '元数据',noCache: true, activeMenu:'/openTools' }
        },
        {
          path:'openMoreFile',
          name:'openMoreFile',
          component: () => import('@/views/openMoreFile/index.vue'),
          meta: { title: '资料库-更多',noCache: true, activeMenu:'/openDataBank' }
        },
        {
          path:'openMoreFile2',
          name:'openMoreFile2',
          component: () => import('@/views/openMoreFile2/index.vue'),
          meta: { title: '案例库-更多',noCache: true, activeMenu:'/openFiles' }
        },
        {
          path:'openMoreFile3',
          name:'openMoreFile3',
          component: () => import('@/views/openMoreFile3/index.vue'),
          meta: { title: '应用指南-更多',noCache: true, activeMenu:'/openTools' }
        },
        {
          path:'openMoreFile4',
          name:'openMoreFile4',
          component: () => import('@/views/openMoreFile4/index.vue'),
          meta: { title: '项目成果-更多',noCache: true, activeMenu:'/openHome' }
        },
        {
          path:'openDetils',
          name:'openDetils',
          component: () => import('@/views/openDetils/index.vue'),
          meta: { title: '详情',noCache: true}
        },
		{
		  path:'homeDetils',
		  name:'homeDetils',
		  component: () => import('@/views/openHomeDetils/index.vue'),
		  meta: { title: '首页资料详情',noCache: true, activeMenu:'/openHome' }
		},
		{
		  path:'riskDetils',
		  name:'riskDetils',
		  component: () => import('@/views/openRiskDetils/index.vue'),
		  meta: { title: '风险案例详情',noCache: true, activeMenu:'/openEnvironmentRisk' }
		},
        {
          path:'openDetilsFiles',
          name:'openDetilsFiles',
          component: () => import('@/views/openDetilsFiles/index.vue'),
          meta: { title: '资料库详情',noCache: true, activeMenu:'/openDataBank' }
        },
        {
          path:'openDetilsTool',
          name:'openDetilsTool',
          component: () => import('@/views/openDetilsTool/index.vue'),
          meta: { title: '服务工具详情',noCache: true, activeMenu:'/openTools' }
        }
      ]
    },

    {
      path: '/sysHome',
      redirect:'/sysHome/sHome',
      component:Layout,
      children:[{
        path: 'sHome',
        name:'sHome',
        component:()=>import('@/views/System/Home/Hindex.vue'),
        meta: { title: '系统一览', icon:'house',noCache: true }
      }]
    },
  // {
  //   path: '/sysResource',
  //   redirect:'/sysResource/resource',
  //   component:Layout,
  //   children:[{
  //     path: 'resource',
  //     name:'resource',
  //     component:()=>import('@/views/System/resource/Rindex.vue'),
  //     meta: { title: '资源管理', icon:'house',noCache: true }
  //   }]
  // },
  // {
  //   path: '/sysarea',
  //   redirect:'/sysarea/areaIndex',
  //   component:Layout,
  //   children:[{
  //     path: 'areaIndex',
  //     name:'areaIndex',
  //     component:()=>import('@/views/System/Area/Aindex.vue'),
  //     meta: { title: '区域管理', icon:'location',noCache: true }
  //   }]
  // },
  // {
  //   path: '/plat',
  //   redirect:'/plat/platForm',
  //   component:Layout,
  //   children:[{
  //     path: 'platForm',
  //     name:'platForm',
  //     component:()=>import('@/views/System/platForm/Pindex.vue'),
  //     meta: { title: '应用管理', icon:'cellphone',noCache: true }
  //   }]
  // },
  // {
  //   path: '/sysu',
  //   redirect:'/sysu/sysUser',
  //   component:Layout,
  //   children:[{
  //     path: 'sysUser',
  //     name:'sysUser',
  //     component:()=>import('@/views/System/User/Uindex.vue'),
  //     meta: { title: '用户管理', icon:'user',noCache: true }
  //   }]
  // },
  // {
  //   path: '/sysr',
  //   redirect:'/sysr/sysRole',
  //   component:Layout,
  //   children:[{
  //     path: 'sysRole',
  //     name:'sysRole',
  //     component:()=>import('@/views/System/Role/Rindex.vue'),
  //     meta: { title: '角色管理', icon:'magicStick',noCache: true }
  //   }]
  // },
  // {
  //   path: '/syslg',
  //   redirect:'/syslg/sysLog',
  //   component:Layout,
  //   children:[{
  //     path: 'sysLog',
  //     name:'sysLog',
  //     component:()=>import('@/views/System/Log/LGindex.vue'),
  //     meta: { title: '日志管理', icon:'calendar',noCache: true }
  //   }]
  // },
]



const router = createRouter({
    history: createWebHistory(),
    routes:constantRouterMap
})

export default router