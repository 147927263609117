<template>
    <div v-if="!item.hidden">
        <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
                <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
                    <el-menu-item :index="resolvePath(onlyOneChild.path)" >
                        <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.title" />
                    </el-menu-item>
                </app-link>
        </template>

        <el-sub-menu v-else :index="resolvePath(item.path)" popper-append-to-body>
            <template #title>
                <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
            </template>
            <menu-item
                v-for="child in item.children"
                :key="child.path"
                :item="child"
                :base-path="resolvePath(child.path)"
                class="nest-menu"
            />
        </el-sub-menu>
    </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
export default {
    name: 'MenuItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    components: { Item, AppLink },
    data(){
        return{
            onlyOneChild:null
        }
    },
    methods:{
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter(item => {
                if (item.hidden) {
                    return false
                } else {
                    this.onlyOneChild = item
                    return true
                }
            })
            if (showingChildren.length === 1) {
                return true
            }
            if (showingChildren.length === 0) {
                
                this.onlyOneChild = { ... parent, path: '', noShowingChildren:true }
               
                return true
            }
            return false
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath
            }
            if (isExternal(this.basePath)) {
                return this.basePath
            }
            return path.resolve(this.basePath, routePath)
        }
    }
}
</script>

<style lang='scss' scoped>
.el-menu-item{
    font-size: 18px;
    height: 70px;
    line-height:70px;
}
.el-menu-item.is-active{
  background-color:#5e080500;
  background-image: url('../../../assets/img/active.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
:root{
    --el-menu-hover-bg-color: #4f535640;
}
</style>