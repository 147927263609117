<template>
    <div v-if="item.outNav&&item.outNav==1">
        <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
                <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
                    <el-menu-item :index="resolvePath(onlyOneChild.path)" >
                        <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.title" />
                    </el-menu-item>
                </app-link>
        </template>

        <el-sub-menu v-else :index="resolvePath(item.path)">
            <template #title>
                <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
            </template>
            <menu-item
                v-for="child in item.children"
                :key="child.path"
                :item="child"
                :base-path="resolvePath(child.path)"
                class="nest-menu"
            />
        </el-sub-menu>
    </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
export default {
    name: 'MenuItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        basePath: {
            type: String,
            default: ''
        }
    },
    components: { Item, AppLink },
    data(){
        return{
            onlyOneChild:null
        }
    },
    methods:{
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter(item => {
                if (!item.outNav) {
                    return false
                } else {
                    this.onlyOneChild = item
                    return true
                }
            })
            if (showingChildren.length === 1) {
                return true
            }
            if (showingChildren.length === 0) {
                
                this.onlyOneChild = { ... parent, path: '', noShowingChildren:true }
               
                return true
            }
            return false
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath
            }
            if (isExternal(this.basePath)) {
                return this.basePath
            }
            return path.resolve(this.basePath, routePath)
        }
    }
}
</script>

<style lang='scss' scoped>
.el-menu-item.is-active {
    background-color:#4f535680;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-sub-menu__title{
    color: #000;
}
.el-sub-menu .is-active{
    background-color: rgb(60, 122, 230);
}
</style>