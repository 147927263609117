import axios from 'axios'
import { ElNotification } from 'element-plus'
import { getToken } from '@/utils/auth'
import store from '../store'
import Cookies from 'js-cookie'
const service = axios.create({
  baseURL: window.g.ServerPath, 
  timeout: window.g.Timeout,
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
        config.headers['Authorization'] = "Bearer " + getToken()
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.state
    if (code < 200 || code > 300) {
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        ElNotification.warning({
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (code) {
        if (code === 401) {
          store.dispatch('TokenClear').then(() => {
            Cookies.set('point', 403)
            location.reload()
          })
          }else{
            const errorMsg = error.response.data.message
            if (errorMsg !== undefined) {
                ElNotification.warning({
                    title: errorMsg,
                    duration: 5000
                })
            }
        }
    } else {
      ElNotification.warning({
        title: '接口请求失败',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)
export default service
