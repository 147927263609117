<template>
  <router-view/>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}

body,html{
  background-color: #DFE5EF;
}


</style>
