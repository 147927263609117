import { getInfo, login, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
const user = {
  state: {
    token: getToken(),
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false,
    siteId:'50010301',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus
    },
    SET_siteId:(state, siteId) => {
      state.siteId = siteId
    }
  },

  actions: {
     // 登录
     Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(r => {
          let res = r.data
          setToken(res.token, false)
          commit('SET_TOKEN', res.token)
          setUserInfo(res, commit)
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit('SET_LOAD_MENUS', true)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出接口
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
         logout().then(res => {
            logOut(commit)
            resolve()
          }).catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          setUserInfo(res.data, commit)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    updateLoadMenus({commit},mainMenus) {
      return new Promise((resolve, reject) => {
         commit('SET_LOAD_MENUS', false)
      })
    },

    TokenClear({ commit }){
      logOut(commit)
    }
  }
}

export const logOut = (commit) => {
  commit('SET_TOKEN', '')
  commit('SET_ROLES', [])
  removeToken()
}

export const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if(res.user.roles.length>0){
    commit('SET_ROLES', res.user.roles)
  }else{
    commit('SET_ROLES', 'userRole')
  }
  commit('SET_USER', res)

}



export default user
