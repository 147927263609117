import { constantRouterMap } from '@/router/routers.js'
import Layout from '@/components/Layout/index.vue'
const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: [],
        sidebarRouters: constantRouterMap
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)
        },
        SET_SIDEBAR_ROUTERS: (state, routers) => {
            state.sidebarRouters = constantRouterMap.concat(routers)
        }
    },
    actions: {
        GenerateRoutes({ commit }, asyncRouter) {
            commit('SET_ROUTERS', asyncRouter)
        },
        SetSidebarRouters({ commit }, sidebarRouter) {
            commit('SET_SIDEBAR_ROUTERS', sidebarRouter)
        }
    }
}

export const filterAsyncRouter = (routers, lastRouter = false, type = false) => {
    return routers.filter(router => {
        if (type && router.children) {
            router.children = filterChildren(router.children)
        }
        if (router.component) {
            if(router.component === 'Layout') { // Layout组件特殊处理
                router.component = Layout
            }else{
                const component = router.component
                router.component = loadView(component)
            }
        }
        if (router.children != null && router.children && router.children.length) {
            router.children = filterAsyncRouter(router.children, router, type)
        } else {
            delete router['children']
            delete router['redirect']
        }
        return true
    })
}

function filterChildren(childrenMap, lastRouter = false) {
    var children = []
        childrenMap.forEach((el) => {
    if (lastRouter) {
        el.path = lastRouter.path + '/' + el.path
    }
        children = children.concat(el)
    })
    return children
}

export const loadView = (view) => {
    return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
