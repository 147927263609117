<template>
    <div class="menuBox">
      <el-menu
        :default-active="activeMenu"
        mode="horizontal"
        text-color="#fff"
        active-text-color="#5cb3ff"
        :ellipsis="false"
       >
        
          <div class="nameLogo">
            <img src="../../../assets/siteImg/logo.png"/>
            馆藏文物预防性保护风险防控管理服务平台
          </div>
       
        <div class="flex-grow" /> 
        <menu-item v-for="route in  sidebarRouters" :key="route.path" :item="route"  :base-path="route.path" />
      </el-menu>
    </div>
</template>

<script>
import MenuItem from './menuItem.vue'
import { mapGetters } from 'vuex'
export default{
  components:{
    MenuItem
  },
  computed:{
    ...mapGetters([
      'sidebarRouters',
      ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  data(){
    return{
    }
  }
}

</script>

<style lang="scss" scoped>
:root{
    --el-menu-hover-bg-color: #4f535640;
}
.flex-grow {
  flex-grow: 1;
}
.el-menu{
    background-color: rgba($color: #000000, $alpha: 0);
}
.el-menu--horizontal{
  border-bottom: solid 0px #6691f5;
}
.nameLogo{
  font-size: 28PX;
  font-weight: bold;
  height: 59PX;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  color: white;
  img{
    width: 26PX;
    height: 50PX;
    object-fit: contain;
    margin-right: 10PX;
  }
}
</style>