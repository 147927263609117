<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h) {
    const { icon, title } = h.$props
    const vnodes = []
    if (icon) {
      vnodes.push(<svg-icon icon-class={icon}/>)
    }
    if (title) {
      vnodes.push(<span class="titleMargin" slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>
<style scoped>
.titleMargin{
  margin-left: 10px;
}
</style>
