import request from '@/utils/request'

export function getAllUser(params) {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params
  })
}
export function addUser(data) {
  return request({
    url: '/sys/user/add',
    method: 'post',
    data
  })
}

export function delUser(id) {
  return request({
    url: `/sys/user/delete?id=${id}`,
    method: 'get',
  })
}
export function linkZone(params) {
  return request({
    url: '/sys/user/linkZone',
    method: 'get',
    params
  })
}

export function updateStatusUser(data) {
  return request({
    url: '/sys/user/updateStatus',
    method: 'get',
    params:data
  })
}

export function editUser(data) {
  return request({
    url: '/sys/user/update',
    method: 'post',
    data
  })
}

// 重置密码
export function resetPassword(data) {
  return request({
    url: '/sys/user/updatePassword',
    method: 'get',
    params:data
  })
}


export function userRegister(data) { //用户注册
  return request({
    url: '/open/register',
    method: 'post',
    data
  })
}

export function museumList(data) { //博物馆列表
  return request({
    url: '/open/listSite',
    method: 'get',
    params:data
  })
}