<template>
    <el-container style="width: 100%; height: 100%;">
        <el-header class="header">
            <div class="headerLeft">
                <menu-page/>
            </div>
            <div class="headerRight">
                <el-dropdown @command="handleCommand">
                    <div class="nameCss">
                        <div class="imgUser">
                            <svg-icon icon-class="user"/>
                        </div>
                        <div style="color: white;">
                            {{user.user.username}}
                            <svg-icon icon-class="arrowDown"/>
                        </div>
                    </div>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-menu>
                            <el-dropdown-item command="a">门户网站</el-dropdown-item>
                            <el-dropdown-item command="b">管理中心</el-dropdown-item>
                            <el-dropdown-item command="c">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </el-header>
        <el-main>
            <div class="mainContain">
                <router-view/>
            </div>
        </el-main>
    </el-container>
</template>
<script>
import menuPage from './Menu/index.vue'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
export default {
components:{
    menuPage,
},
computed:{
    ...mapGetters(['user']),
},
data(){
    return{
        
    }
},
created(){
    if(this.user.zoneTree.type==3){
        this.$store.commit('SET_siteId', this.user.zoneTree.code)
    }else{
        let id = this.$route.query.id
        if(this.$route.query.id){
            Cookies.set('yz-siteId', id)
            this.$store.commit('SET_siteId', id)
        }else{
            let oldId = Cookies.get('yz-siteId')
            this.$store.commit('SET_siteId', oldId)
        }
    }
},
mounted(){
},
methods:{
    logOut(){
        this.$store.dispatch('LogOut').then(()=>{
            location.reload()
        })
    },
    handleCommand(type){
           if(type == 'b'){
                this.urlResolve('sHome')
           }else if(type == 'a'){
                this.urlResolve('openHome')
           }else{
                this.logOut()
           }
        },
        urlResolve(name){
            let routeUrl = this.$router.resolve({
                'name':name
            })
            window.open(`${routeUrl.href}`, '_blank')
        }
}   
}
</script>
<style lang="scss" scoped>

.mainContain{
width: 100%;
height: 100%;
}
.header{
display: flex;
justify-content: space-between;
align-items: center;
}
.headerLeft{
width: calc(100% - 200px);
height: 100%;
}
.headerRight{
width: 150px;
margin-left: 30px;
height: 100%;
display: flex;
align-items: center;
}
.el-header{
background-image: url('../../assets/siteImg/navBg.png');
background-size: 100% 100%;
background-repeat: no-repeat;

}
.el-main{
width: 100%;
height: 100%;
padding: 0px;
overflow: auto;
box-sizing: border-box;
background-color: #ECF0F7;
}
.nameCss{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgUser{
        width: 30px;
        height: 30px;
        margin-right:6px;
        display: inline-block;
        border-radius: 50%;
        background: rgb(225, 225, 225);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(0, 0, 0);
    }
}
:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}
</style>