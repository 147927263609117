const getters = {
    token: state => state.user.token,
    roles: state => state.user.roles,
    user: state => state.user.user,
    loadMenus: state => state.user.loadMenus,
    siteId:state => state.user.siteId,

    permission_routers: state => state.permission.routers,
    addRouters: state => state.permission.addRouters,
    sidebarRouters: state => state.permission.sidebarRouters,
}
export default getters