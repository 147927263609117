<template>
    <div id="pageLayout">
        <el-container>
            <el-aside>
                <menu-page/>
            </el-aside>
            <el-main>
                <div class="mainContain">
                    <div class="header">
                        <div class="userMsg">
                            <el-dropdown @command="handleCommand">
                                <div class="nameCss">
                                    <div class="imgUser">
                                        <svg-icon icon-class="user"/>
                                    </div>
                                    <div>
                                        {{user.user.username}}
                                        <svg-icon icon-class="arrowDown"/>
                                    </div>
                                </div>
                                <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-menu>
                                        <el-dropdown-item command="a">门户网站</el-dropdown-item>
                                        <el-dropdown-item command="b">数据中心</el-dropdown-item>
                                        <el-dropdown-item command="c">退出登录</el-dropdown-item>
                                    </el-dropdown-menu>
                                    <!-- <el-dropdown-item><span @click="logOut">退出登录</span></el-dropdown-item> -->
                                </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="bodymain">
                        <router-view/>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<script>
import menuPage from './Menu/index.vue'
import { mapGetters } from 'vuex'
export default {
    components:{
        menuPage,
    },
    computed:{
      ...mapGetters([
        'user',
      ]),
    },
    mounted(){
    
    },
    methods:{
        logOut(){
            this.$store.dispatch('LogOut').then(()=>{
                location.reload()
            })
        },
        handleCommand(type){
           if(type == 'b'){
            switch (this.user.zoneTree.type){
                case 1:
                    this.urlResolve('countryScreenNew')
                    break;
                case 2:
                    this.urlResolve('areaScreenNew')
                    break;
                default: 
                    this.urlResolve('siteHome')
            }
           }else if(type == 'a'){
                this.urlResolve('openHome')
           }else{
                this.logOut()
           }
        },
        urlResolve(name){
            let routeUrl = this.$router.resolve({
                'name':name
            })
            window.open(`${routeUrl.href}`, '_blank')
        }
    }   
}
</script>
<style lang="scss" scoped>
#pageLayout{
    width: 100%;
    height: 100vh;
    background: #F5F1EE;
   
}
.el-container{
    height: 100%;
}
.el-aside {
    background-image: url('../../assets/img/s-menu.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 300px;
}
.el-main{
    --el-main-padding: 0px;
    height: 100%;
    padding-top: 55px;
    box-sizing: border-box;
}
.mainContain{
    width: 100%;
    height: 100%;
}
.header{
    height: 55px;
    position: fixed;
    top:0px;
    right: 0px;
    display: flex;
    width: calc(100% - 320px);
    z-index: 2;
    background-color: #f5f1ee;
    justify-content:flex-end;
    align-items: center;
    padding: 0px 10px;
    color: rgb(8, 8, 8);
    border-bottom: 1px solid #BEBEBE;
}
.locationCss{
    margin-right: 50px;
}
.userMsg{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nameCss{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgUser{
        width: 30px;
        height: 30px;
        margin-right:6px;
        display: inline-block;
        border-radius: 50%;
        background: rgb(225, 225, 225);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(173, 4, 4);
    }
}
.bodymain{
    width: 100%;
}
:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}
</style>
