<template>
    <div class="menuBox">
      <div class="logo">
        馆藏文物预防性保护<br/>
        风险防控管理服务平台
      </div>
      <el-menu
        :unique-opened="true" 
        :default-active="activeMenu"
        active-text-color="#854933"
        text-color="#ffffff">
        <menu-item v-for="route in  sidebarRouters" :key="route.path" :item="route"  :base-path="route.path" />
      </el-menu>
    </div>
</template>

<script>
import MenuItem from './menuItem.vue'
import { mapGetters } from 'vuex'
export default{
  components:{
    MenuItem
  },
  computed:{
    ...mapGetters([
      'sidebarRouters',
      ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  data(){
    return{
    }
  }
}

</script>

<style lang="scss" scoped>
.logo{
  width: 100%;
  font-size: 24px;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
  line-height: 40px;
  color: #E6D888;
}
.el-menu{
  background-color:rgba($color: #000000, $alpha: 0);
  border-right:0px;
}


</style>