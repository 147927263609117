import request from '@/utils/request'
export function login(data){
  return request({
    url: '/sso/doLogin',
    method: 'post',
    data
  })
}

export function getInfo(){ //所有用户信息
  return request({
    url: '/sys/user/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/sso/logout',
    method: 'get'
  })
}

export function getCodeImg(){
  return request({
    url: '/sys/user/vCode',
    method: 'get',
  })
}

